import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "@w3f/gatsby-theme-w3f/src/utils/i18n";
import Layout from "@w3f/gatsby-theme-w3f/src/components/site/Layout";
import SEO from "@w3f/gatsby-theme-w3f/src/components/site/SEO";
import Container from "layouts/Container";

import "./privacy-and-cookies.scss";

const PrivacyAndCookies = ({ data: { page, footerImage } }) => {
  const { t } = useTranslation();
  const { updatedAt, seo } = page.frontmatter;
  const title = t("Privacy Policy");

  return (
    <Layout footerImage={footerImage}>
      {/* Adds default coming from the article, but can be overwritten by Seo fields */}
      <SEO title={(seo && seo.title) || title} description={seo && seo.description} image={seo && seo.image} />
      <Container>
        <div className="privacy-and-cookies">
          <time className="privacy-and-cookies__date t-light-gray">Updated: {updatedAt}</time>
          <h1 className="privacy-and-cookies__title t-title-1">{title}</h1>
          <hr className="privacy-and-cookies__divider" />
          <div className="privacy-and-cookies__body t-text" dangerouslySetInnerHTML={{ __html: page.html }} />
        </div>
      </Container>
    </Layout>
  );
};

export default PrivacyAndCookies;

export const query = graphql`
  query ($language: String!) {
    ...Locales
    page: markdownRemark(
      fields: { langKey: { eq: $language } }
      fileAbsolutePath: { regex: "//pages/privacy-and-cookies.md/" }
    ) {
      html
      frontmatter {
        updatedAt: updated_at(formatString: "Y-MM-DD")
        seo {
          ...Seo
        }
      }
    }
    footerImage: file(name: { eq: "home2-large" }) {
      ...BgImage
    }
  }
`;
